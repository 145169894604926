import { Box, Text } from '@chakra-ui/react';
import { H1, Layout, ResponsiveFlex, ResponsiveGrid, SEO } from 'components';
import { graphql, HeadProps } from 'gatsby';
import React from 'react';
import { CategoryPageQuery } from '../../../gatsby-graphql';
import ArticleCard from './ArticleCard';

interface Props {
  data: CategoryPageQuery;
}

const CategoryTemplate = ({ data }: Props) => {
  console.log({ data });
  if (!data.categoriesJson) {
    throw new Error('there is no JSON File for this category');
  }

  const articles = data.allMdx.edges;
  const { title, description, slug: categorySlug } = data.categoriesJson;

  const articlePreviews = articles.map(({ node }) => (
    <ArticleCard article={node} key={node.excerpt} />
  ));

  return (
    <Layout>
      <ResponsiveFlex maxW={['full', '40%']} margin={'auto'} minH={'80vh'}>
        <H1>{title}</H1>

        <ResponsiveFlex padding={0} gap={3}>
          {articlePreviews}
        </ResponsiveFlex>
  
      </ResponsiveFlex>
    </Layout>
  );
};

export const Head = ({
  location: { pathname },
  data: { categoriesJson },
}: HeadProps<CategoryPageQuery>) => {
  if (!categoriesJson?.description) return null;

  return (
    <SEO
      pathName={pathname}
      title={`${categoriesJson?.title} - Andrea Franceschini`}
      description={categoriesJson?.description}
    />
  );
};

export const query = graphql`
  query CategoryPage($slug: String) {
    allMdx(
      sort: { order: DESC, fields: frontmatter___createdAt }
      filter: { frontmatter: { category: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            category
            slug
            title
          }
          slug
          excerpt
        }
      }
    }
    categoriesJson(slug: { eq: $slug }) {
      title
      description
      slug
    }
  }
`;

export default CategoryTemplate;
