import { H3, H2, ResponsiveFlex } from 'components';
import React from 'react';
import { CategoryPageQuery } from '../../../gatsby-graphql';
import { Link as GatsbyLink } from 'gatsby';
import { Text } from '@chakra-ui/react';

interface Props {
  article: CategoryPageQuery['allMdx']['edges'][0]['node'];
}

const ArticleCard = ({ article }: Props) => {
  if (!(article && article.frontmatter)) {
    return <p>error</p>;
  }

  const { slug, title } = article.frontmatter;

  // boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px;" borderRadius={5}

  return (
    <ResponsiveFlex gap={1} pl={0} pr={0} pb={0}>
      <H2 as={GatsbyLink} to={slug || ''} _hover={{ textDecor: 'underline' }} color={'accent'}>
        {title}
      </H2>
      <Text>{article.excerpt}</Text>
    </ResponsiveFlex>
  );
};

export default ArticleCard;
